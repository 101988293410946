// Generated by Framer (8289486)

import { addFonts, cx, CycleVariantState, SVG, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["XYtbwAb7g"];

const variantClassNames = {XYtbwAb7g: "framer-v-1wsiy41"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "XYtbwAb7g", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "XYtbwAb7g", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-qFsI4", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1wsiy41", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"XYtbwAb7g"} ref={ref} style={{...style}} transition={transition}><SVG className={"framer-1d55o45"} data-framer-name={"Icon / Chevron Down"} fill={"rgba(0,0,0,1)"} intrinsicHeight={49} intrinsicWidth={49} layoutDependency={layoutDependency} layoutId={"xAZwGMVZo"} style={{rotate: 180}} svg={"<svg width=\"49\" height=\"49\" viewBox=\"0 0 49 49\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M12.6185 30.8496C19.2473 30.8496 24.6208 25.476 24.6208 18.8473C24.6208 25.476 29.9943 30.8496 36.623 30.8496\" stroke=\"white\" stroke-width=\"2\"/>\n</svg>\n"} transition={transition} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-qFsI4 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qFsI4 .framer-1r0t7gt { display: block; }", ".framer-qFsI4 .framer-1wsiy41 { height: 200px; position: relative; width: 200px; }", ".framer-qFsI4 .framer-1d55o45 { flex: none; height: 49px; left: 0px; position: absolute; top: 0px; width: 49px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerNaZb3m0OC: React.ComponentType<Props> = withCSS(Component, css, "framer-qFsI4") as typeof Component;
export default FramerNaZb3m0OC;

FramerNaZb3m0OC.displayName = "Elemental/ Custom Arrow Animation";

FramerNaZb3m0OC.defaultProps = {height: 200, width: 200};

addFonts(FramerNaZb3m0OC, [])